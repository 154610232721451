import api from "../../api"
import { _fetchJson } from "ts/autoload/_fetch"
import { Datatable } from "../datatable"
import { nextTick } from "#page-builder/nextTick"

export class BlockedItem {
  public id: number
  public ip: string
  public user_agent	: string
  public denial_reason: number
  public created_at: Date

  constructor(i: any) {
    Object.assign(this, i)
  }

  public formattedCreatedAt(): string {
    return new Date(this.created_at).toLocaleString()
  }

  public formattedDenialReason(): string {
    //@ts-ignore
    switch (parseInt(this.denial_reason)) {
      case 1: return 'Did not click Sponsered Ads'
      case 2: return 'Fake mobile browsers/Fake mobile apps'
      case 3: return 'Desktop browsers'
      case 4: return 'Tablet browsers'
      case 5: return 'IP address blocked temporarily'
      case 6: return 'VPN/Proxy'
      case 7: return 'Blocked by Blacklist'
      case 8: return ''
      case 9: return ''
    }
  }
}

export class BlockedListModal extends Datatable {
  private _modal: Function
  public loading: boolean
  
  constructor(items: any[], selectAllCheckbox: HTMLInputElement) {
    super(items, {
      ipOrUserAgent: '',
    })
    //
    this._selectAllCheckbox = selectAllCheckbox
  }

  static create(items: any[], selectAllCheckbox: HTMLInputElement): BlockedListModal {
    let blockedList = []
    for (let i of items) {
      const p = new BlockedItem(i)
      //
      blockedList.push(p)
    }
    return new BlockedListModal(blockedList, selectAllCheckbox)
  }

  protected isEqual(a: any, b: any): boolean {
    return a.id == b.id
  }

  public resetSearch() {
    this._search = {
      ipOrUserAgent: '',
    }
    //
    this.doSearch()
  }

  protected matchesSearchCriteria(item: any): boolean {
    return (item.ip as string).toLowerCase().includes(this.search.ipOrUserAgent.toLowerCase())
      || (item.user_agent as string).toLowerCase().includes(this.search.ipOrUserAgent.toLowerCase())
  }

  async refresh(type: number) {
    try {
      const json = await _fetchJson(api.setQueryParams({ type }).CAMPAIGN_BLOCKED_LIST_URI, {}) as any
      if (json) {
        const items: BlockedItem[] = []
        for (let i of json.items) {
          items.push(new BlockedItem(i))
        }
        //
        this.clear()
        this.addItems(items)
      } else {
        this.closeModal()
      }
    } finally {
      this.loading = false
    }
  }

  showModal(type: number) {
    this.loading = false
    //
    this.refresh(type)
    //
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show()
  }

  closeModal() {
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide()
  }

  public closeToolsDropdown(el: HTMLElement) {
    nextTick(function() {
      const btn = el.querySelector('[data-bs-toggle="dropdown"]')
      const dropdown = bootstrap.Dropdown.getInstance(btn)
      if (dropdown) {
        dropdown.hide()
      }
    })
  }
}