import api from "../../api"
import { _fetchJson, _fetchJson2 } from "../../_fetch"
import { csrfToken } from "../../csrf"
import { ButtonKind, showSuccess, showWarning } from "../../dialogs"
import { blockForm } from "../../utils"

declare var pageForm: HTMLFormElement

function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export class PageEdit {
  private _modal: Function
  //
  public loading: boolean = true
  //
  public working: boolean = false
  //
  public campaignId: number = 0
  //
  public activeDomains: any[] = []
  //
  public domainId: string = ''
  //
  public path: string = ''
  //
  public archived: boolean = false
  //
  public totalProtectionEnabled: boolean = true
  //
  public latestRevisionPublished: boolean = false

  constructor(
    public id: number = 0,
    public name: string = '',
  ) {
    //
    this.reset()
  }

  public getDomainById(id: number): string {
    for (let i of this.activeDomains) {
      if (i.id == id) {
        return i.domain
      }
    }
    return '<unknown domain>'
  }

  public get urlPreview(): string {
    if (this.domainId)
      //@ts-ignore
      return 'https://' + this.getDomainById(this.domainId) + '/' + trim(this.path, '/')
    else
      return '<Please select a domain>'
  }

  public reset() {

  }

  // Được gọi qua sự kiện shown.bs.modal
  public async refresh() {
    try {
      this.loading = true
      //
      const json = await _fetchJson(api.setParams({ page_id: this.id }).PAGE_JSON_URI)
      //
      if (json) {
        Object.assign(this, json)
      } else {
        this.closeModal()
      }
    } finally {
      this.loading = false
    }
  }

  showModal(jsonStr: string) {
    this.loading = true
    Object.assign(this, JSON.parse(jsonStr))
    //
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show()
  }

  closeModal() {
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide()
  }

  public json(): string {
    const { $event, working, campaign, activeDomains, ...result } = JSON.parse(JSON.stringify(this))
    return JSON.stringify(result)
  }

  public async submit() {
    try {
      this.working = true
      //
      blockForm(pageForm, true)
      //
      const res = await _fetchJson2(api.PAGE_EDIT_URI, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-csrf-token': csrfToken()
        },
        body: this.json()
      })
      //
      if (res) {
        const self = this
        typeof res.hasChanges != 'undefined' && res.hasChanges? 
          showWarning('The page has been saved but <b>still not published with new change</b>! Would you like to publish now?', {
            buttons: [
              {
                kind: ButtonKind.No,
                dismiss: true
              },
              {
                kind: ButtonKind.Yes,
                dismiss: true,
                onclick: function() {
                  self.publish()
                }
              }
            ]
          })
          : showSuccess('The page has been saved')
      }
    } finally {
      this.working = false
      blockForm(pageForm, false)
      this.closeModal()
    }
  }

  public async publish() {
    const res = await _fetchJson2(api.setParams({ page_id: this.id }) .PAGE_PUBLISH_URI, {
      method: 'POST',
    })
    //
    if (res) {
      showSuccess(`The page has been published at :link`, { link: res.url })
      this.latestRevisionPublished = true
    }
  }

  async archive($dispatch: Function) {
    try {
      this.working = true
      //
      const json = await _fetchJson2(api.setParams({ page_id: this.id }).PAGE_ARCHIVE_URI, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-csrf-token': csrfToken()
        },
        body: JSON.stringify({ id: this.id, archived: !this.archived })
      })
      //
      if (json) {
        this.archived = json.archived
        if (json.archived) {
          showSuccess(`The page has been archived`)
        } else {
          const self = this
          showWarning('The page has been unarchived but <b>still not published</b>! Would you like to publish now?', {
            buttons: [
              {
                kind: ButtonKind.No,
                dismiss: true
              },
              {
                kind: ButtonKind.Yes,
                dismiss: true,
                onclick: function() {
                  self.publish()
                }
              }
            ]
          })
        }
        //
        this.closeModal()
        //@ts-ignore
        $dispatch('editPageSuccess', JSON.stringify(json))
      }
    } finally {
      this.working = false
    }
  }
}