/**
 * CHÚ Ý QUAN TRỌNG: 
 *   cẩn thận với instanceof vì AlpineJs dùng Proxy nên khi gọi instanceof CLASS => false!!!!!!!
 */

import { compose } from "#page-builder/compose"
import { OptionalOf } from "#page-builder/optional-of"
import { withImageCropper } from "#page-builder/utils/image-cropper"
import { LiveChat } from "#page-builder/live-chat"
import { getMessageBubbleStyle, MessageBubbleStyle } from "#page-builder/types/message-bubble-style"
import { getSurveyStyle, SurveyStyle } from "#page-builder/types/survey-style"
import { Button, ButtonStyle, BUTTON_WIDTH_CSS_VAR } from "#page-builder/types/buttons"
import { makeid10 } from "#page-builder/utils"
import {
  Background,
  BgStyle,
  Borders,
  BoxShadow,
  CssSizeValue,
  DeviceTypes,
  Dimension,
  DropShadow,
  GridSizing,
  HorizontalAlignment,
  Margin,
  Metadata,
  Padding,
  Protection,
  Sticky,
  TextToSpeech,
  Typography,
  TypographyPerDevice,
  VerticalAlignment,
  Visibility
} from "#page-builder/types/common"
import { DialogStyle } from "#page-builder/types/dialog-style"
import { IBlock, IPageBuilder, ISection, ILiveChatElement } from "#page-builder/interfaces"

declare var bootstrap: any
declare class HTMLElement {}

export const THE_END_OF = -1
export const THE_BEGINNING_OF = -2
export const RIGHT_BEFORE = -3
export const RIGHT_AFTER = -4

function isDesigner() {
  //@ts-ignore
  return typeof __isDesigner != 'undefined'
}

export enum ElementType {
  Base             = 0,
  Wysiwyg          = 1,
  ButtonGroup      = 2,
  Image            = 3,
  Video            = 4,
  Carousel         = 5,
  Ticker           = 6,
  OnlineIndicator  = 7,
  OnlineIndicator2 = 8,
  Form             = 9,
  LiveChat         = 50,
  Quiz             = 60,
  Survey           = 70,
  Custom           = 100,

  Block            = 1000,
  Section          = 10000
}

type ElementTypeKeys = keyof typeof ElementType;

let ElementClassMap: { [type in ElementTypeKeys]: typeof BaseElement | null } = {
  Base            : null,
  Wysiwyg         : null,
  ButtonGroup     : null,
  Image           : null,
  Video           : null,
  Carousel        : null,
  Ticker          : null,
  OnlineIndicator : null,
  OnlineIndicator2: null,
  Form            : null,
  LiveChat        : null,
  Quiz            : null,
  Survey          : null,
  Custom          : null,

  Block           : null,
  Section         : null
}

export class BaseElement {
  public uuid: string = makeid10()
  public type: ElementType = ElementType.Base
  // Tên gợi nhớ do người dùng đặt
  public name: string = ''
  public verticalAlignment: VerticalAlignment = VerticalAlignment.Start
  public horizontalAlignment: HorizontalAlignment = HorizontalAlignment.Center
  public meta: Metadata = new Metadata()
  public background: Background = new Background()
  public dimension: Dimension = new Dimension()
  public margin: Margin = new Margin()
  public padding: Padding = new Padding()
  public borders: Borders = new Borders()
  public visibility: Visibility = new Visibility()
  public boxShadow: BoxShadow = new BoxShadow()
  public dropShadow: DropShadow = new DropShadow()
  public typography: Typography = new Typography()
  public protection: Protection = Protection.None
  // Place on it's own line
  public placeOnSingleRow: boolean = true
  // Tránh alpinejs eval lỗi undefined
  readonly isElement: boolean = true
  readonly isButtonGroup: Boolean = false
  readonly isWysiwyg: boolean = false
  readonly isImage: boolean = false
  readonly isTicker: boolean = false
  readonly isOnlineIndicator: boolean = false
  readonly isOnlineIndicator2: boolean = false
  readonly isHtmlForm: boolean = false
  readonly isLiveChat: boolean = false
  readonly isSurvey: boolean = false
  //
  public contextMenu = {
    show: false,
    position: {
      top: 0,
      right: 0
    }
  }

  constructor(options: OptionalOf<BaseElement> = {}) {
    const self = this
    // Do các class con sẽ khởi tạo các field (eg: public liveChat = new LiveChat())
    // ở sau câu super(options), dẫn đến câu lệnh self.fromJSON sẽ thực hiện trước khi các field đó
    // được khởi tạo => lỗi undefined
    queueMicrotask(function() {
      self.fromJSON(options as any)
    })
  }

  static create(options: OptionalOf<BaseElement>): BaseElement {
    //@ts-ignore
    let result = new ElementClassMap[ElementType[options.type]](options)
    return result
  }

  public clone(): BaseElement {
    return BaseElement.create(this.pojo)
  }

  public get basicHtml(): string {
    return '[ placeholder ]'
  }

  public get typeName(): string {
    switch (parseInt(this.type.toString())) {
      case ElementType.ButtonGroup: return 'button-group'
      case ElementType.Carousel: return 'carousel'
      case ElementType.Image: return 'image'
      case ElementType.Ticker: return 'ticker'
      case ElementType.OnlineIndicator: return 'online-indicator'
      case ElementType.OnlineIndicator2: return 'online-indicator2'
      case ElementType.Form: return 'form'
      case ElementType.LiveChat: return 'live-chat'
      case ElementType.Quiz: return 'quiz'
      case ElementType.Survey: return 'survey'
      case ElementType.Wysiwyg: return 'wysiwyg'
      case ElementType.Video: return 'video'
      case ElementType.Custom: return 'custom'
    }
    return ''
  }

  public get pojo(): any {
    return {
      uuid: this.uuid,
      type: this.type,
      name: this.name,
      meta: this.meta.pojo,
      background: this.background.pojo,
      dimension: this.dimension.pojo,
      margin: this.margin.pojo,
      padding: this.padding.pojo,
      borders: this.borders.pojo,
      boxShadow: this.boxShadow,
      dropShadow: this.dropShadow,
      visibility: this.visibility.pojo,
      typography: this.typography.pojo,
      verticalAlignment: this.verticalAlignment,
      horizontalAlignment: this.horizontalAlignment,
      protection: this.protection,
      placeOnSingleRow: this.placeOnSingleRow
    }
  }

  public get elementID(): string {
    return this.meta.id || `${this.typeName}-${this.uuid}`
  }

  public getHorizontalAlignmentCss(flexBox: boolean = true) {
    let result = ''
    switch (this.horizontalAlignment) {
      case HorizontalAlignment.Center: result = this.placeOnSingleRow && flexBox? 'justify-content: center;': 'margin: 0 auto;'; break;
      case HorizontalAlignment.End: result = this.placeOnSingleRow && flexBox? 'justify-content: end;': 'margin: 0 0 0 auto;'; break;
      case HorizontalAlignment.Start: result = this.placeOnSingleRow && flexBox? 'justify-content: start;': 'margin: 0 0 0 0;'; break;
    }

    return result
  }

  public get protectionClass(): string {
    switch (this.protection) {
      case Protection.HideFromBlockedVisitors: return 'hfbv'
      case Protection.ShowToBlockedVisitors: return 'stbv'
    }
    return ''
  }

  public get wrapperCssStyleObject(): object {
    let verticalAlignment = ''
    if (this.verticalAlignment) {
      verticalAlignment = `align-self: ${this.verticalAlignment}`
    }
    //
    const result = {
      // Ticker yêu cầu set width ở wrapper
      dimension: this.dimension.cssStyle,
      // Đặt margin ở đây vì người dùng có thể thiết lập margin ở cssStyleObject
      margin: this.getHorizontalAlignmentCss(),
      verticalAlignment,
      placeOnSingleRow: this.placeOnSingleRow? `display: flex; flex-basis: 100%;`: '',
    }

    return result
  }

  public get wrapperCssStyle(): string {
    return Object.values(this.wrapperCssStyleObject).filter(i => i).join(';')
  }

  public get wrapperCssClassObject(): Record<string, string> {
    return {
      protection: this.protectionClass
    }
  }

  public get wrapperCssClass(): string {
    return Object.values(this.wrapperCssClassObject).filter(i => i).join(' ')
  }

  public get filters() {
    const filters = [
      this.dropShadow.toCssFilter()
    ].filter(i => i)

    return filters.length? `filter: ${filters.join(',')}`: ''
  }

  public get cssStyleObject(): object {
    const result = {
      filter: this.filters,
      background:  this.background.cssStyle,
      borders: this.borders.cssStyle,
      boxShadow: this.boxShadow.cssStyle,
      shadow: this.dropShadow.cssStyle,
      margin: this.margin.cssStyle,
      padding: this.padding.cssStyle,
      typography: this.typography.cssStyle,
      width: this.placeOnSingleRow? 'width: 100%': ''
    }

    return result
  }

  public get cssStyle(): string {
    return Object.values(this.cssStyleObject).filter(i => i).join(';')
  }

  public get cssClass(): string {
    return [this.meta.class, this.typeName, this.wrapperCssClass].filter(i => i).join(' ')
  }

  public fromJSON(json: Record<keyof this, any>): this {
    this.uuid = json.uuid || makeid10()
    this.type = json.type || this.type || ElementType.Base
    // Nếu người dùng chưa đặt tên => dùng type để làm tên mặc định
    this.name = json.name || this.type
    this.verticalAlignment = json.verticalAlignment || VerticalAlignment.Start
    this.horizontalAlignment = json.horizontalAlignment || HorizontalAlignment.Center
    this.protection = json.protection || Protection.None
    this.placeOnSingleRow = json.placeOnSingleRow
    //
    json.meta && this.meta.fromJSON(json.meta)
    json.background && this.background.fromJSON(json.background)
    json.dimension && this.dimension.fromJSON(json.dimension)
    json.margin && this.margin.fromJSON(json.margin)
    json.padding && this.padding.fromJSON(json.padding)
    json.borders && this.borders.fromJSON(json.borders)
    json.visibility && this.visibility.fromJSON(json.visibility)
    json.boxShadow && this.boxShadow.fromJSON(json.boxShadow)
    json.dropShadow && this.dropShadow.fromJSON(json.dropShadow)
    json.typography && this.typography.fromJSON(json.typography)
    //
    return this
  }

  public triggerDefaultAction(_$dispatch: any) {

  }
}

class ButtonGroupElement extends BaseElement {
  readonly isButtonGroup: boolean = true
  //
  public buttons: Button[] = []
  public itemsPerRow: number = 1 // 0: auto
  public equalWidth: boolean = true
  public gap: number = 0.25 // em
  public btnWidth: CssSizeValue = new CssSizeValue({ auto: true })
  public editButton: Button|null = null
  public editStyle: ButtonStyle = new ButtonStyle()
  public applyToAll: boolean = false

  constructor(options: OptionalOf<ButtonGroupElement> = {}) {
    super(options)
    this.type = ElementType.ButtonGroup
  }

  public get cssStyleObject(): {} {
    let result = {
      pbButtonWidth: `${BUTTON_WIDTH_CSS_VAR}: ${this.equalWidth? '100%': 'auto' }`,
      ...super.cssStyleObject
    } as any
    //
    if (this.equalWidth && this.placeOnSingleRow) {
      result['width'] = 'width: 100%';
    }
    // Nếu ko có button nào => xóa thuộc tính borders để designer hiển thị border dashed
    if (!this.buttons.length) {
      delete (result as any).borders
    }

    return result
  }

  public innerStyle(index: number): string {
    if (this.itemsPerRow > 0 && this.equalWidth) {
      return `
        display: grid;
        gap: ${this.gap}em;
        grid-template-columns: repeat(${this.itemsPerRow}, 1fr);
        width: ${this.equalWidth? this.btnWidth.toCss(this.itemsPerRow): 'auto' };
        ${this.placeOnSingleRow? this.getHorizontalAlignmentCss(false): ''}
      `
    } else {
      if (index > 0) {
        return `
          display: flex;
          flex-wrap: wrap;
          gap: ${this.gap}em;
          margin-top: ${this.gap}px;
        `
      } else {
        return `
          display: flex;
          flex-wrap: wrap;
          gap: ${this.gap}em;
        `
      }
    }
  }
  //
  public get buttonGroups() {
    let result = []
    for (let i = 0; i < Math.ceil(this.buttons.length / this.itemsPerRow); i++) {
      let group = []
      //
      for (let j = 0; j < this.itemsPerRow; j++) {
        if (i * this.itemsPerRow + j < this.buttons.length) {
          group.push(this.buttons[i * this.itemsPerRow + j])
        }
      }
      //
      result.push(group)
    }
    return result
  }

  public get pojo(): any {
    let buttons: any[] = []
    for (let i of this.buttons) {
      buttons.push(i.pojo)
    }
    //
    return {
      itemsPerRow: this.itemsPerRow,
      equalWidth: this.equalWidth,
      btnWidth: this.btnWidth.pojo,
      buttons,
      ... super.pojo
    }
  }

  //@ts-ignore
  public fromJSON(json: Record<keyof this, any>): this {
    super.fromJSON(json)
    //
    this.itemsPerRow = typeof json.itemsPerRow != 'undefined'? json.itemsPerRow: 1
    this.equalWidth = typeof json.equalWidth != 'undefined'? json.equalWidth: true
    json.btnWidth && this.btnWidth.fromJSON(json.btnWidth)
    //
    if (json.buttons) {
      let buttons: Button[] = []
      for (let i of json.buttons) {
        buttons.push(new Button(i))
      }
      this.buttons = buttons
    }
    //
    return this
  }

  public move(btn: Button, index: number) {
    if (index >= 0 && index < this.buttons.length) {
      //
      const array = this.buttons.filter(i => i.uuid != btn.uuid)
      //
      array.splice(index, 0, btn)
      //
      this.buttons = array
    }
  }

  public moveUp(btn: Button) {
    const index = this.buttons.findIndex(i => i.uuid == btn.uuid)
    this.move(btn, index - 1)
  }

  public moveDown(btn: Button) {
    const index = this.buttons.findIndex(i => i.uuid == btn.uuid)
    this.move(btn, index + 1)
  }

  public newButton() {
    this.editButton = new Button()
  }

  public edit(button: Button) {
    this.editButton = new Button(button as any)
  }

  public delete(button: Button) {
    this.buttons = this.buttons.filter(i => i.uuid != button.uuid)
  }

  public cancel() {
    this.editButton = null
  }

  public save() {
    if (this.editButton && this.editButton.validate()) {
      try {
        for (let i of this.buttons) {
          if (i.uuid == this.editButton.uuid) {
            i.merge(this.editButton)
            return
          }
        }
        this.buttons.push(this.editButton)
      } finally {
        this.editButton = null
      }
    }
  }

  public showButtonStyleModal(modal: HTMLElement) {
    if (this.editButton)
      this.editStyle.fromJSON(this.editButton.style.pojo as any)
    bootstrap.Modal.getOrCreateInstance(modal, {}).show()
  }

  public applyStyle() {
    if (this.applyToAll) {
      for (let i of this.buttons) {
        i.style.fromJSON(this.editStyle)
      }
    }
    //
    if (this.editButton)
      this.editButton.style.fromJSON(this.editStyle)
  }
}

class WysiwygElement extends BaseElement {
  static defaultContent = 'New text element'
  readonly isWysiwyg: boolean = true

  public html: string = WysiwygElement.defaultContent

  constructor(options: OptionalOf<WysiwygElement> = {}) {
    super(options)
    // Tránh chữ quá sát cạnh màn hình
    this.margin.left.auto = false
    this.margin.left.unit = 'em'
    this.margin.left.value = 0.5
    // Tránh chữ quá sát cạnh màn hình
    this.margin.right.auto = false
    this.margin.right.unit = 'em'
    this.margin.right.value = 0.5

    this.type = ElementType.Wysiwyg
  }

  public get pojo(): any{
    return {
      html: this.html,
      ... super.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>): this {
    super.fromJSON(json)
    this.html = json.html || WysiwygElement.defaultContent
    return this
  }

  public triggerDefaultAction($dispatch: Function) {
    $dispatch('openRichtextEditor', this.html)
  }
}

class ImageElement extends compose(BaseElement, withImageCropper('image', BaseElement)) {
  readonly isImage: boolean = true

  public image: string = ''
  public rounded: number = 0
  /**
   * Nhận giá trị từ 0 đến 1
   */
  public scale: number = 1

  constructor(options: OptionalOf<ImageElement> = {}) {
    super(options)
    this.type = ElementType.Image
  }

  public get cssStyleObject(): {} {
    let result = {
      rounded: `border-radius: ${this.rounded}px`,
      overflow: 'overflow: hidden',
      ...super.cssStyleObject
    }
    return result
  }

  public get scaleCss() {
    if (this.scale < 1) {
      return `zoom: ${this.scale}`
    }
  }

  public get pojo(): any{
    return {
      image: this.image,
      rounded: this.rounded,
      scale: this.scale,
      ... super.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>): this {
    super.fromJSON(json)
    this.image = json.image || ''
    this.rounded = json.rounded || 0
    this.scale = json.scale || 1
    return this
  }

  public removeImage() {
    this.image = ''
  }
}

enum FormElementType {
  textBox = 'textbox'
}

class FormElement {
  public uuid: string = makeid10()
  public type: FormElementType = FormElementType.textBox
  public id: string = ''
  public name: string = 'Input'

  constructor(options: OptionalOf<FormElement> = {}) {
    this.merge(options as any)
  }

  public get pojo() {
    return {
      uuid: this.uuid,
      id: this.id,
      type: this.type,
      name: this.name,
    }
  }

  public merge(that: FormElement): this {
    this.uuid = that.uuid || makeid10()
    this.type = that.type || FormElementType.textBox
    this.id = that.id || ''
    this.name = that.name || 'Input'
    return this
  }

  public validate(): boolean {
    return true
  }
}

class HtmlFormElement extends BaseElement {
  readonly isHtmlForm: boolean = true
  //
  public dataCollectorEndpoint = '/form'
  //
  public inputs: FormElement[] = []
  public submitButton: string = 'Submit'
  public thankyouMessage: string = ''
  //
  public editInput: FormElement|null = null

  constructor(options: OptionalOf<ButtonGroupElement> = {}) {
    super(options)
    this.type = ElementType.Form
  }

  public get cssStyleObject(): {} {
    let result = {
      ...super.cssStyleObject
    }
 

    return result
  }

  public innerStyle(_index: number): string {
    return ``
  }

  public get pojo(): any {
    let inputs: any[] = []
    for (let i of this.inputs) {
      inputs.push(i.pojo)
    }
    //
    return {
      submitButton: this.submitButton,
      dataCollectorEndpoint: this.dataCollectorEndpoint,
      thankyouMessage: this.thankyouMessage,
      inputs: inputs,
      ... super.pojo
    }
  }

  //@ts-ignore
  public fromJSON(json: Record<keyof this, any>): this {
    super.fromJSON(json)
    //
    this.submitButton = json.submitButton || 'Submit'
    this.dataCollectorEndpoint = json.dataCollectorEndpoint || ''
    this.thankyouMessage = json.thankyouMessage || 'Thank you for contacting us'
    //
    if (json.inputs) {
      let inputs: FormElement[] = []
      for (let i of json.inputs) {
        inputs.push(new FormElement(i))
      }
      this.inputs = inputs
    }
    //
    return this
  }

  public move(input: FormElement, index: number) {
    if (index >= 0 && index < this.inputs.length) {
      //
      const array = this.inputs.filter(i => i.uuid != input.uuid)
      //
      array.splice(index, 0, input)
      //
      this.inputs = array
    }
  }

  public moveUp(input: FormElement) {
    const index = this.inputs.findIndex(i => i.uuid == input.uuid)
    this.move(input, index - 1)
  }

  public moveDown(input: FormElement) {
    const index = this.inputs.findIndex(i => i.uuid == input.uuid)
    this.move(input, index + 1)
  }

  public newInput() {
    this.editInput = new FormElement()
  }

  public edit(input: FormElement) {
    this.editInput = new FormElement(input as any)
  }

  public delete(input: FormElement) {
    this.inputs = this.inputs.filter(i => i.uuid != input.uuid)
  }

  public cancel() {
    this.editInput = null
  }

  public save() {
    if (this.editInput && this.editInput.validate()) {
      try {
        for (let i of this.inputs) {
          if (i.uuid == this.editInput.uuid) {
            i.merge(this.editInput)
            return
          }
        }
        this.inputs.push(this.editInput)
      } finally {
        this.editInput = null
      }
    }
  }
}

class TickerElement extends BaseElement {
  static defaultContent = 'Your text here'
  readonly isTicker = true
  //
  public text: string = TickerElement.defaultContent
  public duration: number = 10 // giây

  constructor(options: any = {}) {
    super(options)
    this.type = ElementType.Ticker
    this.background.style = BgStyle.SolidColor
    this.background.color.color = '#000000'
    this.typography.override = true
    this.typography.color.color = '#FFFFFF'
  }

  public get cssStyleObject(): {} {
    let result = {
      duration: `--duration: ${this.duration}s`,
      // Do placeOnSingleRow sẽ dùng display: flex => width sẽ thành auto
      width: this.placeOnSingleRow? 'width: 100%': '',
      ...super.cssStyleObject
    }
    //
    return result
  }

  public get pojo(): any{
    return {
      text: this.text,
      duration: this.duration,
      ... super.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>): this {
    super.fromJSON(json)
    this.text = json.text || TickerElement.defaultContent
    this.duration = json.duration || 10
    return this
  }
}

class OnlineIndicator extends BaseElement {
  public isOnlineIndicator: boolean = true
  public textOnline: string = 'US Support is online'
  public textOffline: string = 'US Support is offline'
  public status: 'auto' | 'online' | 'offline' = 'auto'
  public colorOnline: string = '#198754'
  public colorOffline: string = '#adb5bd'

  constructor(options: any = {}) {
    super({ ...options, type: ElementType.OnlineIndicator })
  }

  public get cssStyleObject(): {} {
    let result = {
      duration: `--online-indicator-color: ${this.colorOnline}`,
      // Do placeOnSingleRow sẽ dùng display: flex => width sẽ thành auto
      width: this.placeOnSingleRow? 'width: 100%': '',
      ...super.cssStyleObject
    }
    //
    return result
  }

  public get pojo(): any{
    return {
      textOnline: this.textOnline,
      textOffline: this.textOffline,
      status: this.status,
      colorOnline: this.colorOnline,
      ... super.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>): this {
    super.fromJSON(json)
    this.textOnline = json.textOnline || 'US Support is online'
    this.textOffline = json.textOffline || 'US Support is offline'
    this.status = json.status || 'auto'
    this.colorOnline = json.colorOnline || '#198754'
    this.colorOffline = json.colorOffline || '#adb5bd'
    return this
  }

  public get wrapperCssClassObject(): Record<string, string> {
    const onlineIndicator = isDesigner()? this.status == 'offline'? 'livechat-offline': 'livechat-online' : ''
    return {
      ...super.wrapperCssClassObject, onlineIndicator
    }
  }

  public get indicatorColorsStyle(): string {
    return `--indicator-color-online: ${this.colorOnline}; --indicator-color-offline: ${this.colorOffline} `
  }
}

class OnlineIndicator2 extends compose(BaseElement, withImageCropper('image', BaseElement)) {
  public isOnlineIndicator2: boolean = true
  public textOnline: string = 'US Support is online'
  public textOffline: string = 'US Support is offline'
  public descriptionOnline: string = 'License ID: xxxxxxxxxxx'
  public descriptionOffline: string = 'License ID: xxxxxxxxxxx'
  public status: 'auto' | 'online' | 'offline' = 'auto'
  public colorOnline: string = '#198754'
  public colorOffline: string = '#adb5bd'
  public animation: boolean = false
  public image: string = ''
  public rounded: number = 0
  public positionX: number = 0
  public positionY: number = 0
  /**
   * Nhận giá trị từ 0 đến 1
   */
  public scale: number = 1

  constructor(options: any = {}) {
    super({ ...options, type: ElementType.OnlineIndicator2 })
  }

  public get cssStyleObject(): {} {
    let result = {
      duration: `--online-indicator-color: ${this.colorOnline}`,
      // Do placeOnSingleRow sẽ dùng display: flex => width sẽ thành auto
      width: this.placeOnSingleRow? 'width: 100%': '',
      ...super.cssStyleObject
    }
    //
    return result
  }

  public get imageCssStyle(): string {
    if (this.scale < 1)
      return `zoom: ${this.scale}; border-radius: 50%; overflow: hidden`
    else
      return `border-radius: 50%; overflow: hidden`
  }

  public get positionCssStyle(): string {
    return `right: ${this.positionX}%; bottom: ${this.positionY}%`
  }

  public get pojo(): any{
    return {
      textOnline: this.textOnline,
      textOffline: this.textOffline,
      descriptionOnline: this.descriptionOnline,
      descriptionOffline: this.descriptionOffline,
      animation: this.animation,
      status: this.status,
      colorOnline: this.colorOnline,
      image: this.image,
      rounded: this.rounded,
      scale: this.scale,
      positionX: this.positionX,
      positionY: this.positionY,
      ... super.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>): this {
    super.fromJSON(json)
    this.textOnline = json.textOnline || 'US Support is online'
    this.textOffline = json.textOffline || 'US Support is offline'
    this.status = json.status || 'auto'
    this.colorOnline = json.colorOnline || '#198754'
    this.colorOffline = json.colorOffline || '#adb5bd'
    this.image = json.image || ''
    this.rounded = json.rounded || 0
    this.scale = json.scale || 1
    this.positionX = json.positionX || 0
    this.positionY = json.positionY || 0
    return this
  }

  public removeImage() {
    this.image = ''
  }

  public get wrapperCssClassObject(): Record<string, string> {
    const onlineIndicator = isDesigner()? this.status == 'offline'? 'livechat-offline': 'livechat-online' : ''
    return {
      ...super.wrapperCssClassObject, onlineIndicator
    }
  }

  public get indicatorColorsStyle(): string {
    return `--indicator-color-online: ${this.colorOnline}; --indicator-color-offline: ${this.colorOffline} `
  }
}

class MessageStyle {
  public avatar: string = ''
  public messageBg: string = ''
  public messageColor: string = ''

  constructor (options: OptionalOf<MessageStyle> = {}) {
    this.fromJSON(options as any)
  }

  public get pojo(): any{
    return {
      avatar: this.avatar, // filename
      messageBg: this.messageBg,
      messageColor: this.messageColor
    }
  }

  //@ts-ignore
  public fromJSON(json: Record<keyof this, any>): this {
    this.avatar = json.avatar || ''
    this.messageBg = json.messageBg || '#0866FF'
    this.messageColor = json.messageColor || '#ffffff'
  }

  public toCss(): string {
    return `
      color: ${this.messageColor};
      background: ${this.messageBg};
    `
  }
}

class LiveChatElement extends BaseElement implements ILiveChatElement {
  readonly isLiveChat = true
  //
  public livechat: LiveChat = new LiveChat()

  public startingMessage = ''
  public startingDelay = 1 // s
  public closePeriod = 5 // min - tính từ lúc bắt đầu
  public closeSessionEnabled = true
  public closedMessage = '' // Một khi người dùng đã bấm CALL sẽ hiển thị thông báo này
  public typingSpeed = 0.5 //s
  public showTimestamp: boolean = false
  public thankyouMessage: string = '' // Msg cho trường hợp ko có node kế tiếp khi bấm button bất kì

  public messageBubbleStyle: MessageBubbleStyle = MessageBubbleStyle.Style1

  public agentStyle = new MessageStyle({
    avatar: 'agent-avatar.svg',
    messageBg: '#E4E6E8',
    messageColor: '#000000'
  })

  public visitorStyle = new MessageStyle({
    avatar: 'visitor-avatar.svg',
  })

  public dialogStyle = new DialogStyle()

  public textToSpeech = new TextToSpeech()

  constructor(options: any = {}) {
    super(options)
    this.type = ElementType.LiveChat
  }

  public get pojo(): any{
    return {
      livechat: this.livechat.saveToJSON(),
      startingMessage: this.startingMessage,
      startingDelay: this.startingDelay,
      closePeriod: this.closePeriod,
      closeSessionEnabled: this.closeSessionEnabled,
      closedMessage: this.closedMessage,
      thankyouMessage: this.thankyouMessage,
      agentStyle: this.agentStyle.pojo,
      visitorStyle: this.visitorStyle.pojo,
      typingSpeed: this.typingSpeed,
      messageBubbleStyle: this.messageBubbleStyle,
      dialogStyle: this.dialogStyle.pojo,
      textToSpeech: this.textToSpeech.pojo,
      ... super.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>): this {
    super.fromJSON(json)
    //
    this.startingMessage = json.startingMessage || 'Your session will be started soon...'
    this.startingDelay = json.startingDelay || 1
    this.closePeriod = json.closePeriod || 5
    this.closeSessionEnabled = json.closeSessionEnabled
    this.closedMessage = json.closedMessage || 'You session is closed.'
    this.typingSpeed = json.typingSpeed || 0.5
    this.thankyouMessage = json.thankyouMessage || 'Thank you for contacting us'
    this.messageBubbleStyle = json.messageBubbleStyle || MessageBubbleStyle.Style1
    //
    json.agentStyle && this.agentStyle.fromJSON(json.agentStyle)
    json.visitorStyle && this.visitorStyle.fromJSON(json.visitorStyle)
    json.dialogStyle && this.dialogStyle.fromJSON(json.dialogStyle)
    json.textToSpeech && this.textToSpeech.fromJSON(json.textToSpeech)
    json.livechat && this.livechat.loadFromJson(json.livechat)
    return this
  }

  public get isEmpty(): boolean {
    return this.livechat.nodes.length <= 1
  }

  public get messageBubbleStyleCssStyle() {
    const scope = `#${this.elementID}`
    return getMessageBubbleStyle.apply(this, [scope])
  }
}

class SurveyElement extends BaseElement {
  readonly isSurvey = true
  //
  public survey: LiveChat = new LiveChat(true)
  //
  public closePeriod = 30 // Sau khi bấm CALL <closePeriod>s sẽ đóng conversation này
  public closedMessage = '' // Một khi người dùng đã bấm CALL sẽ hiển thị thông báo này
  public thankyouMessage = 'Thank you for submiting'
  public buttonWidth: CssSizeValue = new CssSizeValue({ auto: true })
  public style: SurveyStyle = SurveyStyle.Style1

  constructor(options: any = {}) {
    super(options)
    this.type = ElementType.Survey
  }

  public get pojo(): any{
    return {
      survey: this.survey.saveToJSON(),
      closePeriod: this.closePeriod,
      closedMessage: this.closedMessage,
      thankyouMessage: this.thankyouMessage,
      style: this.style,
      buttonWidth: this.buttonWidth,
      ... super.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>): this {
    super.fromJSON(json)
    //
    this.closePeriod = json.closePeriod || 30
    this.closedMessage = json.closedMessage || 'The survey is closed.'
    this.thankyouMessage = json.thankyouMessage || 'Thank you for submiting'
    this.style = json.style || SurveyStyle.Style1
    //
    json.buttonWidth && this.buttonWidth.fromJSON(json.buttonWidth)
    json.survey && this.survey.loadFromJson(json.survey)
    //
    return this
  }

  public get cssStyleObject(): object {
    const { typography, ...result } = super.cssStyleObject as any
    return result
  }

  public get isEmpty(): boolean {
    return this.survey.nodes.length <= 1
  }

  public get dummyQuestion() {
    const result = this.isEmpty? null: this.survey.nodes[1]
    return result
  }

  public get firstQuestion() {
    if (!this.isEmpty) {
      const start = this.survey.nodes[0]
      return this.survey.getNodeByID(start._nextNodeUuid) || this.survey.nodes[1]
    }
    return null
  }

  public get surveyCssStyle(): string {
    const scope = `#${this.elementID}`
    return getSurveyStyle(scope, this.buttonWidth, this.typography)
  }
}

enum BlockDirection {
  Row    = 1,
  Column = 2
}

export class Block implements IBlock {
  readonly isBlock = true

  public uuid: string = makeid10()
  public type: ElementType = ElementType.Block
  public name: string = ''
  public meta: Metadata = new Metadata()
  public background: Background = new Background()
  public dropShadow: DropShadow = new DropShadow()
  public dimension: Dimension = new Dimension()
  public margin: Margin = new Margin()
  public padding: Padding = new Padding()
  public borders: Borders = new Borders()
  public visibility: Visibility = new Visibility()
  public typography: Typography = new Typography()
  public protection: Protection = Protection.None

  public sizing: GridSizing = new GridSizing()
  public elements: BaseElement[] = []
  public direction: BlockDirection = BlockDirection.Column
  //
  public showAddNewPopup: boolean = false
  //
  public contextMenu = {
    show: false,
    position: {
      top: '0px',
      left: '0px'
    }
  }

  public showContextMenu($event: any) {
    this.contextMenu.show = true
    this.contextMenu.position.left = $event.clientX + 'px'
    this.contextMenu.position.top = $event.clientY + 'px'
  }

  public contextMenuStyle() {
    return typeof this.contextMenu != 'undefined'? this.contextMenu.position: {}
  }

  constructor(options: OptionalOf<Block> = {}) {
    this.fromJSON(options as any)
  }

  public newWysiwyg(options: OptionalOf<WysiwygElement> = {}): WysiwygElement {
    let result = new ElementClassMap.Wysiwyg!(options)
    this.elements.push(result)
    return result as WysiwygElement
  }

  public newButtonGroup(options: OptionalOf<ButtonGroupElement> = {}): ButtonGroupElement {
    let result = new ElementClassMap.ButtonGroup!(options)
    this.elements.push(result)
    return result as ButtonGroupElement
  }

  public newImage(options: OptionalOf<ImageElement> = {}): ImageElement {
    let result = new ElementClassMap.Image!(options)
    this.elements.push(result)
    return result as ImageElement
  }

  public newTicker(options: OptionalOf<TickerElement> = {}): TickerElement {
    let result = new ElementClassMap.Ticker!(options)
    this.elements.push(result)
    return result as TickerElement
  }

  public newLiveChat(options: OptionalOf<LiveChatElement> = {}): LiveChatElement {
    let result = new ElementClassMap.LiveChat!(options)
    this.elements.push(result)
    return result as LiveChatElement
  }

  public newSurvey(options: OptionalOf<SurveyElement> = {}): SurveyElement {
    let result = new ElementClassMap.Survey!(options)
    this.elements.push(result)
    return result as SurveyElement
  }

  public getGridClass(): string {
    return `pb-block-mobile-${this.sizing.mobile} pb-block-tablet-${this.sizing.tablet} pb-block-desktop-${this.sizing.desktop}`
  }

  public find(uuid: string): any {
    for (let i of this.elements) {
      if (i.uuid == uuid) {
        return i
      }
    }
    return null
  }

  public contains(uuid: string): boolean {
    return !!this.find(uuid)
  }

  public move(elm: BaseElement, index: number) {
    if (index >= 0 && index < this.elements.length) {
      //
      const array = this.elements.filter(i => i.uuid != elm.uuid)
      //
      array.splice(index, 0, elm)
      //
      this.elements = array
      //
      return true
    }
  }

  public moveUp(elm: BaseElement) {
    const index = this.elements.findIndex(i => i.uuid == elm.uuid)
    return this.move(elm, index - 1)
  }

  public moveDown(elm: BaseElement) {
    const index = this.elements.findIndex(i => i.uuid == elm.uuid)
    return !this.move(elm, index + 1)
  }

  public insert(item: any, options: { index?: number, position?: number, item?: any } = {}) {
    insert.apply(this, [item, 'elements', options])
  }

  public clone(): Block {
    return new Block(this.pojo as any)
  }

  public loadElements(arr: BaseElement[] = []) {
    const elements: BaseElement[] = []
    //
    for (let i of arr) {
      elements.push(BaseElement.create(i as any))
    }
    // Do ở BaseElement.create dùng queueMicrotask để nạp
    // dữ liệu nên ở đây cũng dùng queueMicrotask để alpinejs
    // lấy dữ liệu sau khi baseElement.fromJSON hoàn tất
    const self = this
    queueMicrotask(function() {
      self.elements = elements
    })

  }

  public deleteElement(elm: BaseElement) {
    this.elements = this.elements.filter(i => i.uuid != elm.uuid)
  }

  public get protectionClass(): string {
    switch (this.protection) {
      case Protection.HideFromBlockedVisitors: return 'hfbv'
      case Protection.ShowToBlockedVisitors: return 'stbv'
    }
    return ''
  }

  public get cssStyleArray(): string[] {
    const result: string[] = [
      this.background.cssStyle,
      this.borders.cssStyle,
      this.margin.cssStyle,
      this.padding.cssStyle,
      this.dimension.cssStyle,
      this.typography.cssStyle,
      this.dropShadow.cssStyle,
    ]

    return result
  }

  public get cssStyle(): string {
    return this.cssStyleArray.filter(i => i).join(' ;')
  }

  public get cssClass(): string {
    return [this.meta.class, this.getGridClass(), this.protectionClass, !this.elements.length? 'empty': ''].filter(i => i).join(' ')
  }

  public get elementID(): string {
    return this.meta.id || `block-${this.uuid}`
  }

  public get pojo() {
    const elements = []
    for (let i of this.elements) {
      elements.push(i.pojo)
    }
    //
    return {
      uuid: this.uuid,
      type: this.type,
      direction: this.direction,
      meta: this.meta.pojo,
      background: this.background.pojo,
      dropShadow: this.dropShadow.pojo,
      dimension: this.dimension.pojo,
      margin: this.margin.pojo,
      padding: this.padding.pojo,
      borders: this.borders.pojo,
      visibility: this.visibility.pojo,
      sizing: this.sizing.pojo,
      typography: this.typography.pojo,
      protection: this.protection,
      elements
    }
  }

  public fromJSON(json: Record<keyof this, any>): this {
    this.uuid = json.uuid || makeid10()
    this.type = ElementType.Block
    this.name = json.name || 'New block'
    this.direction = json.direction
    this.protection = json.protection || Protection.None
    //
    json.meta && this.meta.fromJSON(json.meta)
    json.background && this.background.fromJSON(json.background)
    json.dropShadow && this.dropShadow.fromJSON(json.dropShadow)
    json.dimension && this.dimension.fromJSON(json.dimension)
    json.margin && this.margin.fromJSON(json.margin)
    json.padding && this.padding.fromJSON(json.padding)
    json.borders && this.borders.fromJSON(json.borders)
    json.visibility && this.visibility.fromJSON(json.visibility)
    json.typography && this.typography.fromJSON(json.typography)
    //
    json.sizing && this.sizing.fromJSON(json.sizing)
    json.elements && this.loadElements(json.elements)
    //
    return this
  }
}

export class Section implements ISection {
  readonly BlockClass = Block
  //
  readonly isSection = true

  public uuid: string = makeid10()
  public type: ElementType = ElementType.Section
  public name: string = ''
  public background: Background = new Background()
  public dropShadow: DropShadow = new DropShadow()
  public dimension: Dimension = new Dimension()
  public sticky: Sticky = new Sticky()
  public margin: Margin = new Margin()
  public padding: Padding = new Padding()
  public borders: Borders = new Borders()
  public visibility: Visibility = new Visibility()
  public typography: Typography = new Typography()
  public meta: Metadata = new Metadata()
  public blocks: Block[] = [ new this.BlockClass(), ]
  public protection: Protection = Protection.None
  //
  public showAddNewPopup: boolean = false

  constructor(options: OptionalOf<Section> = {}) {
    this.fromJSON(options as any)
    //
    this.padding.left.auto = false
    this.padding.left.unit = 'em'
    this.padding.left.value = 0.25
    //
    this.padding.right.auto = false
    this.padding.right.unit = 'em'
    this.padding.right.value = 0.25
  }

  private loadBlocks(arr: any[]) {
    const blocks: Block[] = []
    //
    for (let i of arr) {
      blocks.push(new this.BlockClass().fromJSON(i))
    }
    //
    const self = this
    queueMicrotask(function() {
      self.blocks = blocks
    })
  }

  public newBlock(append: boolean = true): Block {
    let result = new this.BlockClass()
    if (append) {
      this.blocks.push(result)
    } else {
      this.blocks.splice(0, 0, result)
    }
    return result
  }

  public get firstBlock(): Block {
    return this.blocks[0]
  }

  public deleteBlock(block: Block): void {
    const blocks = this.blocks.filter(i => i.uuid != block.uuid)
    this.blocks = blocks.length? blocks: [ new this.BlockClass() ]
  }

  public equal(that: Section): boolean {
    return that?.uuid == this.uuid
  }

  public clone(): Section {
    //TODO: xem lai cai nay
    const constructor = Object.getPrototypeOf(this).constructor
    return new constructor(this.pojo as any)
  }

  public find(uuid: string): any {
    for (let i of this.blocks) {
      if (i.uuid == uuid) {
        return i
      } else {
        let temp: BaseElement
        if (temp = i.find(uuid)) {
          return temp
        }
      }
    }
    return null
  }

  public contains(uuid: string): boolean {
    return !!this.find(uuid)
  }

  public move(block: Block, index: number) {
    if (index >= 0 && index < this.blocks.length) {
      //
      const array = this.blocks.filter(i => i.uuid != block.uuid)
      //
      array.splice(index, 0, block)
      //
      this.blocks = array
      //
      return true
    }
  }

  public moveUp(block: Block) {
    const index = this.blocks.findIndex(i => i.uuid == block.uuid)
    return this.move(block, index - 1)
  }

  public moveDown(block: Block) {
    const index = this.blocks.findIndex(i => i.uuid == block.uuid)
    return !this.move(block, index + 1)
  }

  public insert(item: any, options: { index?: number, position?: number, item?: any } = {}) {
    insert.apply(this, [item, 'blocks', options])
  }

  public get pojo() {
    const blocks = []
    for (let i of this.blocks) {
      blocks.push(i.pojo)
    }

    return {
      uuid: this.uuid,
      type: this.type,
      name: this.name,
      protection: this.protection,
      background: this.background.pojo,
      dropShadow: this.dropShadow.pojo,
      dimension: this.dimension.pojo,
      sticky: this.sticky.pojo,
      margin: this.margin.pojo,
      padding: this.padding.pojo,
      borders: this.borders.pojo,
      visibility: this.visibility.pojo,
      meta: this.meta.pojo,
      typography: this.typography.pojo,
      blocks
    }
  }

  public fromJSON(json: Record<keyof this, any>): Section {
    this.uuid = json.uuid || makeid10()
    this.type = ElementType.Section
    this.name = json.name || 'New section'
    this.protection = json.protection || Protection.None
    json.background && this.background.fromJSON(json.background)
    json.dropShadow && this.dropShadow.fromJSON(json.dropShadow)
    json.dimension && this.dimension.fromJSON(json.dimension)
    json.sticky && this.sticky.fromJSON(json.sticky)
    json.margin && this.margin.fromJSON(json.margin)
    json.padding && this.padding.fromJSON(json.padding)
    json.borders && this.borders.fromJSON(json.borders)
    json.visibility && this.visibility.fromJSON(json.visibility)
    json.meta && this.meta.fromJSON(json.meta)
    json.blocks && this.loadBlocks(json.blocks)
    json.typography && this.typography.fromJSON(json.typography)
    //
    return this
  }

  public get protectionClass(): string {
    switch (this.protection) {
      case Protection.HideFromBlockedVisitors: return 'hfbv'
      case Protection.ShowToBlockedVisitors: return 'stbv'
    }
    return ''
  }

  public get cssStyleArray(): string[] {
    const result: string[] = [
      this.background.cssStyle,
      this.borders.cssStyle,
      this.margin.cssStyle,
      this.padding.cssStyle,
      this.dimension.cssStyle,
      this.typography.cssStyle,
      this.dropShadow.cssStyle
    ]

    return result
  }

  public get cssStyle(): string {
    return this.cssStyleArray.filter(i => i).join(' ;')
  }

  public get cssClass(): string {
    return [this.meta.class, this.protectionClass].filter(i => i).join(' ')
  }

  public get elementID(): string {
    return this.meta.id || `section-${this.uuid}`
  }
}

export class PageBuilder implements IPageBuilder {
  protected _typography: TypographyPerDevice = new TypographyPerDevice()
  // Dùng để lưu các tùy chọn của người dùng ở phía browser
  public uuid: string = makeid10()
  //
  public title: string = ''
  //
  public disableRightClickMenu: boolean = true
  // Bao gồm F12
  public disableViewsourceShortcutKey: boolean = true
  //
  public expandedSection: string = '' // uuid
  //
  public background: Background = new Background()
  //
  public dimension: Dimension = new Dimension()
  //
  public borders: Borders = new Borders()
  //
  public dropShadow: DropShadow = new DropShadow()
  //
  public margin: Margin = new  Margin()
  //
  public padding: Padding = new Padding()
  //
  public gridMode: boolean = false
  //
  public _deviceType: DeviceTypes = DeviceTypes.Mobile
  //
  public sections: Section[] = [ new Section() ]
  //
  public newSection(index: number = 0) {
    //
    let result = new SectionClass()
    //
    this.sections.splice(index, 0, result)
    //
    return result
  }
  //
  public deleteSection(section: Section): void {
    const sections = this.sections.filter(i => i.uuid != section.uuid)
    //
    if (section.uuid == this.expandedSection) {
      this.expandedSection = ''
    }
    //
    this.sections = sections.length? sections: [ new SectionClass() ]
  }
  //
  public move(section: Section, index: number) {
    if (index >= 0 && index < this.sections.length) {
      //
      const array = this.sections.filter(i => i.uuid != section.uuid)
      //
      array.splice(index, 0, section)
      //
      this.sections = array
      //
      return true
    }
  }

  public moveUp(section: Section) {
    const index = this.sections.findIndex(i => i.uuid == section.uuid)
    return this.move(section, index - 1)
  }

  public moveDown(section: Section) {
    const index = this.sections.findIndex(i => i.uuid == section.uuid)
    return this.move(section, index + 1)
  }

  /**
   *
   * @param item Đối tượng được insert
   * @param options
   */
  public insert(item: any, options: { index?: number, position?: number, reference?: any, pageBuilder?: PageBuilder } = {}) {
    insert.apply(this, [item, 'sections', options])
  }
  //
  public save() {
    let result = {
      pbVersion: 1,
      title: this.title,
      expandedSection: this.expandedSection,
      disableRightClickMenu: this.disableRightClickMenu,
      disableViewsourceShortcutKey: this.disableViewsourceShortcutKey,
      background: this.background.pojo,
      borders: this.borders.pojo,
      typography: this._typography.pojo,
      dropShadow: this.dropShadow.pojo,
      dimension: this.dimension.pojo,
      margin: this.margin.pojo,
      padding: this.padding.pojo
    } as any
    //
    let sections = []
    for (let i of this.sections) {
      sections.push(i.pojo)
    }
    //
    result.sections = sections
    //
    return result
  }
  //
  public saveToStr(): string {
    return JSON.stringify(this.save())
  }
  //
  public fromJSON(json: OptionalOf<PageBuilder>) {
    json.background && this.background.fromJSON(json.background)
    json.borders && this.borders.fromJSON(json.borders)
    json.dropShadow && this.dropShadow.fromJSON(json.dropShadow)
    json.dimension && this.dimension.fromJSON(json.dimension)
    json.margin && this.margin.fromJSON(json.margin)
    json.padding && this.padding.fromJSON(json.padding)
    //@ts-ignore
    json.typography && this._typography.fromJSON(json.typography)
    this.title = json.title || ''
    this.expandedSection = json.expandedSection!
    this.disableRightClickMenu = json.disableRightClickMenu!
    this.disableViewsourceShortcutKey = json.disableViewsourceShortcutKey!
    //
    const sections: Section[] = []
    if (json && Array.isArray(json.sections)) {
      for (let i of json.sections) {
        sections.push(new SectionClass().fromJSON(i))
      }
    }
    //
    const self = this
    queueMicrotask(function() {
      self.sections = sections.length? sections: [ new SectionClass() ]
    })
    //
    return this
  }
  //
  public loadFromStr(str: string) {
    const json = JSON.parse(str) as OptionalOf<PageBuilder>
    return this.fromJSON(json)
  }
  //
  public find(uuid: string) {
    for (let i of this.sections) {
      if (i.uuid == uuid) {
        return i
      } else {
        let temp = i.find(uuid)
        if (temp) {
          return temp
        }
      }
    }
  }
  /**
   * Tìm cha "thực sự" của item
   */
  public parentOf(item: BaseElement|Block|Section): Block|Section|this|undefined {
    if (isPbElement(item)) {
      for (let section of this.sections) {
        for (let block of section.blocks) {
          if (block.contains(item.uuid)) {
            return block
          }
        }
      }
    }
    if (isPbBlock(item)) {
      for (let i of this.sections) {
        if (i.contains(item.uuid)) {
          return i
        }
      }
    }
    if (isPbSection(item))
      return this
  }
  //
  public get typography(): Typography {
    throw new Error('Not implemented')
  }
  //
  public get cssStyleArray(): string[] {
    const result: string[] = [
      this.background.cssStyle,
      this.borders.cssStyle,
      this.dimension.cssStyle,
      this.dropShadow.cssStyle,
      typeof (this as any).isDesigner !== 'undefined'? this.typography.cssStyle: '',
    ]

    return result
  }

  public get cssStyle(): string {
    return this.cssStyleArray.filter(i => i).join(' ;')
  }
}

//Set giá trị thực cho ElementClassMap
ElementClassMap.Wysiwyg = WysiwygElement
ElementClassMap.ButtonGroup = ButtonGroupElement
ElementClassMap.Image = ImageElement
ElementClassMap.Ticker = TickerElement
ElementClassMap.OnlineIndicator = OnlineIndicator
ElementClassMap.OnlineIndicator2 = OnlineIndicator2
ElementClassMap.Form = HtmlFormElement
ElementClassMap.LiveChat = LiveChatElement
ElementClassMap.Survey = SurveyElement

export let WysiwygElementClass = WysiwygElement
export let ImageElementClass = ImageElement
export let TickerElementClass = TickerElement
export let OnlineIndicatorClass = OnlineIndicator
export let OnlineIndicator2Class = OnlineIndicator2
export let HtmlFormElementClass = HtmlFormElement
export let ButtonGroupElementClass = ButtonGroupElement
export let SurveyElementClass = SurveyElement
export let LiveChatElementClass = LiveChatElement
export let BlockClass = Block
export let SectionClass = Section
//
export function setBlockClass(cls: typeof Block) {
  BlockClass = cls
}
//
export function setSectionClass(cls: typeof Section) {
  SectionClass = cls
}
//
export function isPbElement(item: any): boolean {
  return typeof item.isElement != 'undefined' && item.isElement
}
//
export function isPbBlock(item: any): boolean {
  return typeof item.isBlock != 'undefined' && item.isBlock
}
//
export function isPbSection(item: any): boolean {
  return typeof item.isSection != 'undefined' && item.isSection
}
//
export function isPbPageBuilder(item: any): boolean {
  return typeof item.sections != 'undefined'
}

function indexOf(item: Section|Block, array: any[], position?: number): number|undefined {
  let result = undefined
  if (item) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].uuid == item.uuid) {
        result = i
        break
      }
    }
  }

  if (result !== undefined && position) {
    // Chèn vào đúng vị trí tìm được thì item tự động lùi lại sau
    if (position == RIGHT_BEFORE)
      return result
    if (position == RIGHT_AFTER)
      return ++result
    if (position == THE_BEGINNING_OF)
      return 0
    if (position == THE_END_OF)
      return array.length
  }

  return result
}

// Yêu cầu truyền this vào để reactive nhận biết
function insert(this: Block|Section|PageBuilder, item: any, array: string, options: {
  index?: number,
  position?: number,
  reference?: any,
  pageBuilder?: PageBuilder
} = {}) {
  let index = options.index
  let reference = options.reference
  // Tìm vị trí cần insert
  while (index == undefined && reference) {
    //@ts-ignore
    index = indexOf(reference, this[array], options.position)
    //@ts-ignore
    reference = options.pageBuilder.parentOf(reference)
  }
  // Nếu không có đối tượng để tham chiếu (!options.reference) => cho vào đầu hoặc cuối cùng
  if (!options.reference) {
    if (options.position == THE_BEGINNING_OF) {
      move.apply(this, [item, array, 0, false])
    } else {
      //@ts-ignore
      this[array].push(item)
    }
  } else if (options.position == THE_END_OF || (options.position == RIGHT_AFTER && options.reference.uuid == this.uuid))
    //@ts-ignore
    this[array].push(item)
  else if (options.position == THE_BEGINNING_OF)
    move.apply(this, [item, array, 0, false])
  else
    move.apply(this, [item, array, index || 0, false])
}

function move(this: Block|Section|PageBuilder, item: any, field: string, index: number, doNotAppend: boolean = true) {
  //@ts-ignore
  if (index >= 0 && index < this[field].length) {
    //@ts-ignore
    const array = this[field].filter(i => i.uuid != item.uuid)
    //
    array.splice(index, 0, item)
    //@ts-ignore
    this[field] = array
    //
    return true
    //@ts-ignore
  } else if (index == this[field].length && !doNotAppend) {
    //@ts-ignore
    this[field].push(item)
  }
}