import { csrfToken } from "./csrf"
import { showError, showValidationError } from "./dialogs"
import { hideLoadingIndicator, showLoadingIndicator } from "./loading-indicator"

export async function _fetchJson(input: RequestInfo | URL, init?: RequestInit): Promise<any|void> {
  try {
    const headers: HeadersInit = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-csrf-token': csrfToken(),
    }

    const res = await fetch(input, { ...init, headers })

    if (!res.ok) {
      try {
        // Thử convert sang JSON
        const json = await res.json()
        return showError(json.message)
      } catch {
        return res.status == 422? showValidationError((await res.json()).details): showError(`${res.statusText}: ${await res.text()}`)
      }
    }
    //
    const json = await res.json()
    if (!json.success) {
      return showError(json.message)
    }
    //
    return json
  } catch (e) {
    showError(e.message)
  }
}

/**
 * _fetchJson có loadingIndicator
 * @param input 
 * @param init 
 */
export async function _fetchJson2(input: RequestInfo | URL, init?: RequestInit): Promise<any|void> {
  showLoadingIndicator()
  try {
    return await _fetchJson(input, init)
  } finally {
    hideLoadingIndicator()
  }
}