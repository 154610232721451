import { Datatable } from "../datatable"

enum ConversionRateType {
  Success = 'success',
  Good    = 'good',
  Bad     = 'bad',
  Worse   = 'worse'
}

export class HelpItem {
  public caption: string
  public description: string
  public icon: string

  constructor(i: any) {
    Object.assign(this, i)
  }
}

export class Helps extends Datatable {
  //
  static create(items: any[]) {
    let helps = []
    for (let i of items) {
      const p = new HelpItem(i)
      helps.push(p)
    }
    return new Helps(helps)
  }

  constructor(items: any[]) {
    super(items, {
      text: ''
    })
  }

  protected isEqual(a: any, b: any): boolean {
    return a.id == b.id
  }

  public resetSearch() {
    this._search = {
      text: ''
    }
    //
    this.doSearch()
  }

  protected matchesSearchCriteria(item: any): boolean {
    const result = ((item.caption as string).toLowerCase().includes(this.search.text.toLowerCase()) || !this.search.text)
    return result
  }

}